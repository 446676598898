<template>
  <div id="cms-inventory">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Inventory</h2></b-col>
        <b-col>
          <router-link class="link-color" :to="{ name: 'CmsInventoryCreate' }" @click.prevent>
            <b-button type="submit" variant="success">Add Inventory</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    components: {
      SideMenu
    }
  }
</script>